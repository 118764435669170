const hamburgerBtn = document.getElementById("hamburger-btn");
const hamburgerMenu = document.getElementById("hamburger-menu");
const hamburgerContent = document.getElementById("hamburger-content");
const hamburgerClose = document.getElementById("hamburger-close");

const closeHamburger = () => {
  hamburgerMenu.classList.remove("is-active");
};

hamburgerBtn.addEventListener("click", () => {
  hamburgerMenu.classList.add("is-active");
});

hamburgerClose.addEventListener("click", () => {
  closeHamburger();
});

hamburgerMenu.addEventListener("click", (e) => {
  if (e.target === hamburgerMenu) {
    closeHamburger();
  }
});
